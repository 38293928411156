// src/components/SubmissionForm.js

import React, { forwardRef } from 'react';
import './SubmissionForm.css';

const SubmissionForm = forwardRef(({
  isOpen,
  tokenName,
  setTokenName,
  tokenShorthand,
  setTokenShorthand,
  tokenAddress,
  setTokenAddress,
  paymentAmount,
  setPaymentAmount,
  tokenImage,
  setTokenImage,
  isDonating,
  handleSubmit,
  handleImageUpload,
  currentBlockchain,
  MINIMUM_PAYMENT
}, ref) => {
  if (!isOpen) return null;

  return (
    <div id="submission-form" className="side-menu open" ref={ref}>
      <h2>Submit Your Token</h2>
      <form onSubmit={handleSubmit}>
        <input 
          type="text"
          placeholder="Token Name"
          value={tokenName}
          onChange={(e) => setTokenName(e.target.value)}
          required
          disabled={isDonating}
        />
        <input 
          type="text"
          placeholder="Token Shorthand"
          value={tokenShorthand}
          onChange={(e) => setTokenShorthand(e.target.value)}
          required
          disabled={isDonating}
        />
        <input 
          type="text"
          placeholder="Token Address"
          value={tokenAddress}
          onChange={(e) => setTokenAddress(e.target.value)}
          required
        />
        <input 
          type="number"
          step="0.000001"
          min={MINIMUM_PAYMENT[currentBlockchain]}
          placeholder={`Min: ${MINIMUM_PAYMENT[currentBlockchain]} ${currentBlockchain === 'ethereum' ? 'ETH' : 'SOL'}`}
          value={paymentAmount}
          onChange={(e) => setPaymentAmount(e.target.value)}
          required
        />
        <input 
          type="file"
          accept="image/*"
          onChange={handleImageUpload}
          required
          disabled={isDonating}
        />
        {tokenImage && <div id="image-preview" style={{backgroundImage: `url(${tokenImage})`}}></div>}
        <button type="submit">{isDonating ? 'Donate' : 'Submit'}</button>
      </form>
    </div>
  );
});

export default SubmissionForm;
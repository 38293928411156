// src/components/WalletModal.js

import React from 'react';
import './WalletModal.css';

const WalletModal = ({ isOpen, onClose, handleConnectWallet }) => {
  if (!isOpen) return null;

  return (
    <div className="modal">
      <div className="modal-content">
        <h2>Connect your wallet</h2>
        <button onClick={() => handleConnectWallet('phantom')}>Phantom (SOL)</button>
        <button onClick={() => handleConnectWallet('solflare')}>Solflare (SOL)</button>
        <button onClick={() => handleConnectWallet('metamask')}>MetaMask (ETH)</button>
        <button onClick={() => handleConnectWallet('coinbase')}>Coinbase (ETH)</button>
        <button onClick={onClose}>Close</button>
      </div>
    </div>
  );
};

export default WalletModal;
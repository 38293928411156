// src/components/InfoMenu.js

import React from 'react';
import './InfoMenu.css';

const InfoMenu = ({ isOpen }) => {
  if (!isOpen) return null;

  return (
    <div id="info-menu" className="full-page-menu open">
      <h2>Information</h2>
      <section>
        <h3>Map</h3>
        <p>There are two maps available: Ethereum and Solana. You can switch between the maps by connecting to a wallet that's on the respective blockchain. The current map is shown at the top of the screen.</p>
      </section>
      <section>
        <h3>Submitting Tokens</h3>
        <p>To submit a token, click "Submit Token" and fill in the information. Make sure to connect your wallet beforehand.</p>
      </section>
      <section>
        <h3>Supporting Tokens</h3>
        <p>You can support existing tokens by donating to them. Donations increase the token's size on the map and contribute to its "Most Supported" status.</p>
      </section>
      <section>
        <h3>Copy Addresses</h3>
        <p>You can copy a token's address by clicking on its image on the map.</p>
      </section>
      <section>
        <h3>Token Size</h3>
        <p>Token size is determined by the initial eth/sol amount and donations.</p>
      </section>
      <section>
        <h3>Token Conditions</h3>
        <p>Tokens can have special conditions: "King of the Hill" (largest token) and "Most Supported" (most donations). A rainbow aura shows the token has both.</p>
      </section>
      <section>
        <h3>Token Expiry</h3>
        <p>Tokens expire one week from their submission date (168 hours).</p>
      </section>
      <section>
        <h3>Caution</h3>
        <p>Tokens can be submitted by users other than the original developers. Always verify the token's address before interacting with it.</p>
      </section>
      <div className="copyright">© Square.Dev 2024</div>
    </div>
  );
};

export default InfoMenu;
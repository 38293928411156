// src/components/TokenMap.js

import React from 'react';
import './TokenMap.css';

const TokenMap = ({ tokens, currentBlockchain, kingOfTheHill, mostSupportedToken }) => {
  return (
    <div id="token-map-container">
      <div 
        id="ethereum-map" 
        className="token-map" 
        style={{display: currentBlockchain === 'ethereum' ? 'block' : 'none'}}
      >
        {tokens.ethereum.map(token => (
          <TokenImage 
            key={token.address}
            token={token}
            isKing={token === kingOfTheHill}
            isMostSupported={token === mostSupportedToken}
          />
        ))}
      </div>
      <div 
        id="solana-map" 
        className="token-map" 
        style={{display: currentBlockchain === 'solana' ? 'block' : 'none'}}
      >
        {tokens.solana.map(token => (
          <TokenImage 
            key={token.address}
            token={token}
            isKing={token === kingOfTheHill}
            isMostSupported={token === mostSupportedToken}
          />
        ))}
      </div>
    </div>
  );
};

const TokenImage = ({ token, isKing, isMostSupported }) => (
  <div 
    className={`token-image ${isKing ? 'king-of-the-hill' : ''} ${isMostSupported ? 'most-supported' : ''}`}
    style={{
      backgroundImage: `url(${token.image})`,
      width: `${token.scaledSize}px`,
      height: `${token.scaledSize}px`,
      left: `${token.x}px`,
      top: `${token.y}px`
    }}
  />
);

export default TokenMap;
import React, { useState, useEffect, useCallback, useRef } from 'react';
import { connectWallet, getCurrentWalletInfo, sendTransaction } from './walletConnector';
import TokenMap from './components/TokenMap';
import SubmissionForm from './components/SubmissionForm';
import WalletModal from './components/WalletModal';
import InfoMenu from './components/InfoMenu';
import Header from './components/Header';
import './App.css';

//check

const MAP_WIDTH = 1524;
const MAP_HEIGHT = 660;
const TOP_BAR_HEIGHT = 60;

const MINIMUM_PAYMENT = {
    ethereum: 0.015,
    solana: 0.25
};
const PIXEL_PRICE = {
    ethereum: 0.003,
    solana: 0.05
};
const MIN_SIZE = 2;
const MAX_SIZE = 400;
const MAX_DONATIONS_PER_USER = 5;

function App() {
  const [walletInfo, setWalletInfo] = useState({ currentWallet: null, currentBlockchain: null });
  const [isWalletModalOpen, setIsWalletModalOpen] = useState(false);
  const [tokens, setTokens] = useState({ ethereum: [], solana: [] });
  const [currentBlockchain, setCurrentBlockchain] = useState('solana');
  const [isSubmissionFormOpen, setIsSubmissionFormOpen] = useState(false);
  const [isInfoMenuOpen, setIsInfoMenuOpen] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const [kingOfTheHill, setKingOfTheHill] = useState(null);
  const [mostSupportedToken, setMostSupportedToken] = useState(null);
  const [tokenName, setTokenName] = useState('');
  const [tokenShorthand, setTokenShorthand] = useState('');
  const [tokenAddress, setTokenAddress] = useState('');
  const [paymentAmount, setPaymentAmount] = useState('');
  const [tokenImage, setTokenImage] = useState(null);
  const [ethereumScaleFactor, setEthereumScaleFactor] = useState(1);
  const [solanaScaleFactor, setSolanaScaleFactor] = useState(1);
  const [currentTime, setCurrentTime] = useState(Date.now());
  const [attractionNode, setAttractionNode] = useState({ x: MAP_WIDTH / 2, y: MAP_HEIGHT / 2 });
  const [isFirstToken, setIsFirstToken] = useState(true);
  const [isDonating, setIsDonating] = useState(false);
  const [selectedTokenForDonation, setSelectedTokenForDonation] = useState(null);
  const submissionFormRef = useRef(null);

  useEffect(() => {
    function handleClickOutside(event) {
      if (submissionFormRef.current && !submissionFormRef.current.contains(event.target)) {
        setIsSubmissionFormOpen(false);
      }
    }

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

const handleConnectWalletClick = () => {
  console.log("Connect Wallet button clicked");
  setIsWalletModalOpen(true);
  console.log("isWalletModalOpen set to true");
};

useEffect(() => {
  console.log("isWalletModalOpen:", isWalletModalOpen);
}, [isWalletModalOpen]);

const handleConnectWallet = async (walletType) => {
  console.log("Connecting wallet:", walletType);
  const result = await connectWallet(walletType);
  if (result) {
    setWalletInfo(result);
    setCurrentBlockchain(result.currentBlockchain);
    setIsWalletModalOpen(false);
  }
};

useEffect(() => {
  const info = getCurrentWalletInfo();
  if (info.currentWallet && info.currentBlockchain) {
    setWalletInfo(info);
    setCurrentBlockchain(info.currentBlockchain);
  }
}, []);

useEffect(() => {
  const handleWalletAddressChanged = (event) => {
    setWalletInfo(prevState => ({
      ...prevState,
      currentWallet: event.detail
    }));
  };

  window.addEventListener('walletAddressChanged', handleWalletAddressChanged);

  return () => {
    window.removeEventListener('walletAddressChanged', handleWalletAddressChanged);
  };
}, []);

useEffect(() => {
  if (window.ethereum) {
    window.ethereum.on('accountsChanged', (accounts) => {
      if (accounts.length > 0) {
        setWalletInfo(prevState => ({
          ...prevState,
          currentWallet: accounts[0]
        }));
      } else {
        setWalletInfo({ currentWallet: null, currentBlockchain: null });
      }
    });
  }

  return () => {
    if (window.ethereum && window.ethereum.removeListener) {
      window.ethereum.removeListener('accountsChanged', () => {});
    }
  };
}, []);

  const handleTransaction = async (amount) => {
    try {
      const txHash = await sendTransaction(amount);
      console.log('Transaction successful:', txHash);
      alert(`Transaction successful: ${txHash}`);
      return txHash;
    } catch (error) {
      console.error('Transaction failed:', error);
      alert(`Transaction failed: ${error.message}`);
      throw error;
    }
  };

  const updateKingAndSupported = useCallback(() => {
    const currentTokens = tokens[currentBlockchain];
    const newKing = currentTokens.reduce((largest, token) => 
      token.payment > (largest ? largest.payment : 0) ? token : largest, null);
    setKingOfTheHill(newKing);

    const newMostSupported = currentTokens.reduce((max, token) => 
      calculateSupport(token) > calculateSupport(max) ? token : max, null);
    setMostSupportedToken(newMostSupported);
  }, [tokens, currentBlockchain]);

  const calculateSupport = (token) => {
    return token.donations.length * (0.1 * token.totalDonations);
  };

  useEffect(() => {
    updateKingAndSupported();
  }, [tokens, currentBlockchain, updateKingAndSupported]);

  const addTokenToMap = async (token) => {
    const baseSize = calculateTokenSize(token.payment, true);
    const scaledSize = Math.floor(baseSize * (currentBlockchain === 'ethereum' ? ethereumScaleFactor : solanaScaleFactor));
    const newToken = { ...token, baseSize, scaledSize };
    const placedToken = placeNewToken(newToken);

    if (placedToken) {
      setTokens(prevTokens => ({
        ...prevTokens,
        [currentBlockchain]: [...prevTokens[currentBlockchain], placedToken]
      }));
      updateKingAndSupported();
    } else {
      console.warn(`Failed to add token: ${token.name}`);
    }
  };

  const calculateTokenSize = (payment, isManualSubmission = false) => {
    const baseSize = Math.floor(payment / PIXEL_PRICE[currentBlockchain]);
    if (isManualSubmission) {
      if (baseSize <= 200) {
        return baseSize;
      } else {
        return Math.min(MAX_SIZE, Math.floor(200 + 100 * Math.log2(baseSize / 200)));
      }
    } else {
      return Math.min(200, baseSize);
    }
  };

  const placeNewToken = (newToken) => {
    const currentScaleFactor = currentBlockchain === 'ethereum' ? ethereumScaleFactor : solanaScaleFactor;
    let scaledSize = newToken.scaledSize || Math.floor(newToken.baseSize * currentScaleFactor);
    let position = findCompactPosition(tokens[currentBlockchain], scaledSize);

    if (!position) {
      const newScaleFactor = currentScaleFactor * 0.95;
      if (currentBlockchain === 'ethereum') {
        setEthereumScaleFactor(newScaleFactor);
      } else {
        setSolanaScaleFactor(newScaleFactor);
      }
      const rescaledTokens = rescaleTokens(tokens[currentBlockchain], newScaleFactor);
      setTokens(prevTokens => ({
        ...prevTokens,
        [currentBlockchain]: rescaledTokens
      }));
      scaledSize = Math.floor(newToken.baseSize * newScaleFactor);
      position = findCompactPosition(rescaledTokens, scaledSize);
    }

    if (!position) {
      position = findRandomValidPosition(tokens[currentBlockchain], scaledSize);
    }

    if (position) {
      setIsFirstToken(false);
      return { ...newToken, ...position, scaledSize };
    } else {
      return null;
    }
  };

  const findCompactPosition = (placedTokens, size) => {
    if (isFirstToken) {
      return {
        x: Math.floor(MAP_WIDTH / 2 - size / 2),
        y: Math.floor(MAP_HEIGHT / 2 - size / 2)
      };
    }

    let bestPosition = null;
    let bestScore = Infinity;

    for (let y = 0; y <= MAP_HEIGHT - size; y++) {
      for (let x = 0; x <= MAP_WIDTH - size; x++) {
        if (isPositionValid(placedTokens, { x, y }, size)) {
          const distanceFromAttraction = Math.sqrt(
            Math.pow(x + size/2 - attractionNode.x, 2) + 
            Math.pow(y + size/2 - attractionNode.y, 2)
          ) / 10;
          
          const adjacencyScore = calculateAdjacentScore(placedTokens, { x, y }, size);
          
          const score = distanceFromAttraction - adjacencyScore * 100;

          if (score < bestScore) {
            bestScore = score;
            bestPosition = { x, y };
          }
        }
      }
    }

    return bestPosition;
  };

  const isPositionValid = (placedTokens, position, size) => {
    const minSpace = 2;
    if (position.x < 0 || position.y < 0 || position.x + size > MAP_WIDTH || position.y + size > MAP_HEIGHT) {
      return false;
    }

    for (const token of placedTokens) {
      if (position.x < token.x + token.scaledSize + minSpace &&
          position.x + size + minSpace > token.x &&
          position.y < token.y + token.scaledSize + minSpace &&
          position.y + size + minSpace > token.y) {
        return false;
      }
    }

    return true;
  };

  const calculateAdjacentScore = (placedTokens, position, size) => {
    let score = 0;
    for (const token of placedTokens) {
      if (
        (Math.abs(position.x - (token.x + token.scaledSize)) <= 1 && 
         position.y < token.y + token.scaledSize && 
         position.y + size > token.y) ||
        (Math.abs(position.y - (token.y + token.scaledSize)) <= 1 && 
         position.x < token.x + token.scaledSize && 
         position.x + size > token.x)
      ) {
        score += 1;
      }
    }
    return score;
  };

  const findRandomValidPosition = (placedTokens, size) => {
    const maxAttempts = 1000;
    for (let i = 0; i < maxAttempts; i++) {
      const x = Math.floor(Math.random() * (MAP_WIDTH - size));
      const y = Math.floor(Math.random() * (MAP_HEIGHT - size));
      if (isPositionValid(placedTokens, { x, y }, size)) {
        return { x, y };
      }
    }
    console.warn("Couldn't find a valid random position after " + maxAttempts + " attempts");
    return null;
  };

  const rescaleTokens = (placedTokens, newScaleFactor) => {
    let rescaledTokens = placedTokens.map(token => {
      const scaleRatio = newScaleFactor / (currentBlockchain === 'ethereum' ? ethereumScaleFactor : solanaScaleFactor);
      const newScaledSize = Math.floor(token.baseSize * newScaleFactor);
      
      const newX = Math.floor(token.x * scaleRatio);
      const newY = Math.floor(token.y * scaleRatio);
      
      return {
        ...token,
        scaledSize: newScaledSize,
        x: newX,
        y: newY
      };
    });

    rescaledTokens = rescaledTokens.map(token => {
      if (token.x < 0 || token.y < 0 || token.x + token.scaledSize > MAP_WIDTH || token.y + token.scaledSize > MAP_HEIGHT) {
        const newPosition = findCompactPosition(rescaledTokens.filter(t => t !== token), token.scaledSize);
        if (newPosition) {
          return { ...token, ...newPosition };
        }
      }
      return token;
    });

    for (let i = 0; i < rescaledTokens.length; i++) {
      for (let j = i + 1; j < rescaledTokens.length; j++) {
        if (checkOverlap(rescaledTokens[i], rescaledTokens[j])) {
          const smallerToken = rescaledTokens[i].scaledSize <= rescaledTokens[j].scaledSize ? rescaledTokens[i] : rescaledTokens[j];
          const newPosition = findCompactPosition(rescaledTokens.filter(t => t !== smallerToken), smallerToken.scaledSize);
          if (newPosition) {
            if (smallerToken === rescaledTokens[i]) {
              rescaledTokens[i] = { ...rescaledTokens[i], ...newPosition };
            } else {
              rescaledTokens[j] = { ...rescaledTokens[j], ...newPosition };
            }
          }
        }
      }
    }

    return rescaledTokens;
  };

  const checkOverlap = (token1, token2) => {
    return token1.x < token2.x + token2.scaledSize &&
           token1.x + token1.scaledSize > token2.x &&
           token1.y < token2.y + token2.scaledSize &&
           token1.y + token1.scaledSize > token2.y;
  };

  const handleSubmitToken = async (event) => {
    event.preventDefault();
    if (!walletInfo.currentWallet) {
      alert("Please connect your wallet first!");
      return;
    }

    const payment = parseFloat(paymentAmount);
    if (!validatePaymentAmount(payment)) {
      return;
    }

    try {
      if (isDonating && selectedTokenForDonation) {
        await processDonation(selectedTokenForDonation, payment);
      } else {
        const txHash = await handleTransaction(payment);
        if (txHash) {
          const newToken = {
            name: tokenName,
            shorthand: tokenShorthand,
            address: tokenAddress,
            image: tokenImage,
            payment: payment,
            createdAt: Date.now(),
            donations: [],
            totalDonations: 0
          };
          await addTokenToMap(newToken);
          alert("Token submitted successfully!");
          setIsSubmissionFormOpen(false);
          resetSubmissionForm();
        }
      }
    } catch (error) {
      console.error("Error submitting token:", error);
      alert("Failed to submit token. Please try again.");
    }
  };

  const validatePaymentAmount = (amount) => {
    const minPayment = MINIMUM_PAYMENT[currentBlockchain];
    
    if (isNaN(amount) || amount < minPayment) {
      alert(`Please enter a valid amount of at least ${minPayment} ${currentBlockchain === 'ethereum' ? 'ETH' : 'SOL'}`);
      return false;
    }
    return true;
  };

  const processDonation = async (token, amount) => {
    const userDonations = token.donations.filter(d => d.donor === walletInfo.currentWallet);
    if (userDonations.length >= MAX_DONATIONS_PER_USER) {
      alert(`You have reached the maximum number of donations (${MAX_DONATIONS_PER_USER}) for this token.`);
      return;
    }

    try {
      const txHash = await handleTransaction(amount);
      if (txHash) {
        const updatedToken = {
          ...token,
          donations: [...token.donations, { donor: walletInfo.currentWallet, amount, timestamp: Date.now() }],
          totalDonations: token.totalDonations + amount
        };

        const newBaseSize = calculateTokenSize(updatedToken.payment + updatedToken.totalDonations, true);
        const newScaledSize = Math.floor(newBaseSize * (currentBlockchain === 'ethereum' ? ethereumScaleFactor : solanaScaleFactor));

const tokenIndex = tokens[currentBlockchain].findIndex(t => t.address === token.address);
        if (tokenIndex !== -1) {
          const newTokens = [...tokens[currentBlockchain]];
          newTokens.splice(tokenIndex, 1);
          const relocatedToken = placeNewToken({...updatedToken, baseSize: newBaseSize, scaledSize: newScaledSize});
          if (relocatedToken) {
            newTokens.push(relocatedToken);
            setTokens(prevTokens => ({
              ...prevTokens,
              [currentBlockchain]: newTokens
            }));
            updateKingAndSupported();
          } else {
            console.error("Couldn't find a new position for the donated token");
            return;
          }
        }
        alert(`Donation of ${amount} ${currentBlockchain === 'ethereum' ? 'ETH' : 'SOL'} successfully processed!`);
      }
    } catch (error) {
      console.error("Error processing donation:", error);
      alert("An error occurred while processing your donation. Please try again.");
    }
  };

  const resetSubmissionForm = () => {
    setTokenName('');
    setTokenShorthand('');
    setTokenAddress('');
    setPaymentAmount('');
    setTokenImage(null);
    setIsDonating(false);
    setSelectedTokenForDonation(null);
  };

  const toggleFullscreen = () => {
    if (!document.fullscreenElement) {
      document.documentElement.requestFullscreen();
    } else {
      if (document.exitFullscreen) {
        document.exitFullscreen();
      }
    }
  };

  const searchTokens = (query) => {
    setSearchQuery(query);
  };

  const handleImageUpload = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setTokenImage(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const updateTokenMap = () => {
    const oneWeekAgo = currentTime - 7 * 24 * 60 * 60 * 1000;
    const currentTokens = tokens[currentBlockchain].filter(token => token.createdAt > oneWeekAgo);
    
    if (currentTokens.length < tokens[currentBlockchain].length) {
      const newScaleFactor = Math.min((currentBlockchain === 'ethereum' ? ethereumScaleFactor : solanaScaleFactor) * 1.05, 1);
      const rescaledTokens = rescaleTokens(currentTokens, newScaleFactor);
      
      setTokens(prevTokens => ({
        ...prevTokens,
        [currentBlockchain]: rescaledTokens
      }));

      if (currentBlockchain === 'ethereum') {
        setEthereumScaleFactor(newScaleFactor);
      } else {
        setSolanaScaleFactor(newScaleFactor);
      }
    }

    updateKingAndSupported();
  };

  const relocateOverlappingTokens = () => {
    const currentTokens = tokens[currentBlockchain];
    const updatedTokens = currentTokens.map(token => {
      if (currentTokens.some(otherToken => 
        token !== otherToken && checkOverlap(token, otherToken)
      )) {
        const newPosition = placeNewToken(token);
        return newPosition || token;
      }
      return token;
    });

    setTokens(prevTokens => ({
      ...prevTokens,
      [currentBlockchain]: updatedTokens
    }));
  };

return (
  <div className="App">
    <Header 
      searchQuery={searchQuery}
      setSearchQuery={searchTokens}
      currentBlockchain={currentBlockchain}
      isInfoMenuOpen={isInfoMenuOpen}
      setIsInfoMenuOpen={setIsInfoMenuOpen}
      toggleFullscreen={toggleFullscreen}
      setIsSubmissionFormOpen={setIsSubmissionFormOpen}
      handleConnectWalletClick={handleConnectWalletClick}
      walletInfo={walletInfo}
    />

      <main>
        <TokenMap 
          tokens={tokens}
          currentBlockchain={currentBlockchain}
          kingOfTheHill={kingOfTheHill}
          mostSupportedToken={mostSupportedToken}
        />
        <div id="token-info"></div>
      </main>

      <SubmissionForm 
        ref={submissionFormRef}
        isOpen={isSubmissionFormOpen}
        tokenName={tokenName}
        setTokenName={setTokenName}
        tokenShorthand={tokenShorthand}
        setTokenShorthand={setTokenShorthand}
        tokenAddress={tokenAddress}
        setTokenAddress={setTokenAddress}
        paymentAmount={paymentAmount}
        setPaymentAmount={setPaymentAmount}
        tokenImage={tokenImage}
        setTokenImage={setTokenImage}
        isDonating={isDonating}
        handleSubmit={handleSubmitToken}
        handleImageUpload={handleImageUpload}
        currentBlockchain={currentBlockchain}
        MINIMUM_PAYMENT={MINIMUM_PAYMENT}
      />

    <WalletModal 
      isOpen={isWalletModalOpen}
      onClose={() => setIsWalletModalOpen(false)}
      handleConnectWallet={handleConnectWallet}
    />

      <InfoMenu isOpen={isInfoMenuOpen} />
    </div>
  );
}

export default App;
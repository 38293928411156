// src/components/Header.js

import React from 'react';
import './Header.css';

const Header = ({ 
  searchQuery,
  setSearchQuery,
  currentBlockchain,
  isInfoMenuOpen,
  setIsInfoMenuOpen,
  toggleFullscreen,
  setIsSubmissionFormOpen,
  setIsModalOpen,
  handleConnectWalletClick,
  walletInfo,
  handleConnectWallet 
}) => {
  console.log('Current wallet info:', walletInfo); // Debug log

  return (
    <header>
      <h1>SquareDev</h1>
      <div id="top-bar">
        <div id="search-container">
          <input 
            type="text" 
            id="search-bar" 
            placeholder="Search tokens..." 
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
          />
          <div id="search-results"></div>
        </div>
        <div id="current-map-indicator">
          <span className={currentBlockchain === 'ethereum' ? 'ethereum-text' : 'solana-text'}>
            {currentBlockchain === 'ethereum' ? 'Ethereum' : 'Solana'}
          </span>
        </div>
        <div>
          <button id="info-menu-btn" onClick={() => setIsInfoMenuOpen(!isInfoMenuOpen)}>[{isInfoMenuOpen ? '-' : '+'}]</button>
          <button id="fullscreen-btn" onClick={toggleFullscreen}>Fullscreen</button>
          <button id="submit-token-btn" onClick={() => setIsSubmissionFormOpen(true)}>Submit Token</button>
          <button 
            id="connect-wallet-btn"
            onClick={handleConnectWalletClick}
            className={walletInfo.currentWallet ? 'connected' : ''}
          >
            {walletInfo.currentWallet 
              ? `${walletInfo.currentBlockchain === 'ethereum' ? 'ETH' : 'SOL'}: ${walletInfo.currentWallet.slice(0, 4)}...${walletInfo.currentWallet.slice(-4)}`
              : 'Connect Wallet'}
          </button>
        </div>
      </div>
    </header>
  );
};

export default Header;
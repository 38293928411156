// walletConnector.js
// check

import Web3 from 'web3';
import * as solanaWeb3 from '@solana/web3.js';
import CoinbaseWalletSDK from '@coinbase/wallet-sdk';

let currentWallet = null;
let currentBlockchain = null;
let currentProvider = null;

const ETH_RPC_URL = 'https://eth-mainnet.g.alchemy.com/v2/yfbY1Q2K7RODoC-Z9auuOG-mwe0f4jUh';
const SOL_RPC_URL = 'https://solana-mainnet.g.alchemy.com/v2/yfbY1Q2K7RODoC-Z9auuOG-mwe0f4jUh';

export async function connectWallet(walletType) {
    console.log(`Connecting to ${walletType} wallet`);
    let connected = false;
    let walletAddress = null;

    try {
        switch (walletType) {
            case 'phantom':
            case 'solflare':
                ({ connected, walletAddress } = await connectSolanaWallet(walletType));
                break;
            case 'metamask':
            case 'coinbase':
                ({ connected, walletAddress } = await connectEthereumWallet(walletType));
                break;
            default:
                console.error('Unsupported wallet type');
                return null;
        }

        if (connected && walletAddress) {
            currentWallet = walletAddress;
            currentBlockchain = walletType === 'phantom' || walletType === 'solflare' ? 'solana' : 'ethereum';
            return { currentWallet, currentBlockchain };
        } else {
            console.log(`Failed to connect to ${walletType}. Please try again.`);
            return null;
        }
    } catch (error) {
        console.error(`Error connecting to ${walletType}:`, error);
        return null;
    }
}

async function connectSolanaWallet(walletType) {
    try {
        let provider;
        if (walletType === 'phantom') {
            provider = window.solana;
        } else if (walletType === 'solflare') {
            provider = window.solflare;
        }

        if (provider) {
            if (!provider.isConnected) {
                await provider.connect();
            }
            
            const publicKey = provider.publicKey;
            console.log(`${walletType} wallet response:`, publicKey);
            if (publicKey) {
                currentProvider = provider;
                return { connected: true, walletAddress: publicKey.toString() };
            }
        } else {
            console.error(`No ${walletType} wallet detected. Please install the ${walletType} extension.`);
            alert(`Please install the ${walletType} wallet extension to connect.`);
        }
    } catch (error) {
        console.error(`Error in connect${walletType}:`, error);
    }
    return { connected: false, walletAddress: null };
}

async function connectEthereumWallet(walletType) {
    if (typeof window.ethereum !== 'undefined' || typeof window.web3 !== 'undefined') {
        try {
            let provider;
            if (walletType === 'metamask') {
                provider = window.ethereum;
                // Clear any cached accounts
                await provider.request({
                    method: 'wallet_requestPermissions',
                    params: [{ eth_accounts: {} }]
                });
            } else if (walletType === 'coinbase') {
                const coinbaseWallet = new CoinbaseWalletSDK({
                    appName: 'SquareDev',
                    appLogoUrl: 'https://example.com/logo.png',
                    darkMode: false
                });
                provider = coinbaseWallet.makeWeb3Provider(ETH_RPC_URL, 1);
            }

            // Check if connected to the correct mainnet
            const chainId = await provider.request({ method: 'eth_chainId' });
            if (chainId !== '0x1') { // 0x1 is the chain ID for Ethereum mainnet
                try {
                    await provider.request({
                        method: 'wallet_switchEthereumChain',
                        params: [{ chainId: '0x1' }],
                    });
                } catch (switchError) {
                    throw new Error("Please switch to the Ethereum mainnet to use this application.");
                }
            }

            await provider.request({ method: 'eth_requestAccounts' });
            const web3 = new Web3(provider);
            const accounts = await web3.eth.getAccounts();
            
            if (accounts.length > 0) {
                currentProvider = provider;
                currentWallet = accounts[0];
                currentBlockchain = 'ethereum';

                provider.on('accountsChanged', handleAccountsChanged);
                provider.on('chainChanged', handleChainChanged);
                
                console.log(`${walletType} wallet connected successfully to Ethereum mainnet`);
                console.log("Connected account:", accounts[0]);
                return { connected: true, walletAddress: accounts[0], currentBlockchain: 'ethereum' };
            }
        } catch (error) {
            console.error(`Error connecting to ${walletType} wallet:`, error);
        }
    } else {
        console.log("Ethereum provider not detected");
    }
    return { connected: false, walletAddress: null, currentBlockchain: null };
}

function handleAccountsChanged(accounts) {
    if (accounts.length === 0) {
        console.log('Please connect to the wallet.');
        currentWallet = null;
    } else if (accounts[0] !== currentWallet) {
        currentWallet = accounts[0];
        console.log('Active account changed:', currentWallet);
        // Add this line to update the UI when the account changes
        window.dispatchEvent(new CustomEvent('walletAddressChanged', { detail: currentWallet }));
    }
}

function handleChainChanged(chainId) {
    console.log('Chain changed to:', chainId);
    if (chainId !== '0x1') {
        alert('Please connect to the Ethereum Mainnet');
        // Optionally, you can try to switch the network back to mainnet here
    }
}

export function getCurrentWalletInfo() {
    return { currentWallet, currentBlockchain };
}

export async function sendTransaction(amount) {
    if (!currentProvider) {
        throw new Error("No wallet connected");
    }

    try {
        if (currentBlockchain === 'solana') {
            return await sendSolanaTransaction(amount);
        } else {
            return await sendEthereumTransaction(amount);
        }
    } catch (error) {
        console.error("Transaction failed:", error);
        throw new Error(`Transaction failed: ${error.message}`);
    }
}

async function sendSolanaTransaction(amount) {
    if (!currentProvider || !currentWallet) {
        throw new Error("Wallet not connected");
    }

    const connection = new solanaWeb3.Connection(SOL_RPC_URL, 'confirmed');
    const toAddress = new solanaWeb3.PublicKey('4C5q91TespP4H82wejPYTzX1NC2Nmy82ML2bmk8kxftq');
    const fromPubkey = new solanaWeb3.PublicKey(currentWallet);

    async function retry(fn, retries = 3, delay = 1000) {
        try {
            return await fn();
        } catch (err) {
            if (retries <= 0) throw err;
            await new Promise(resolve => setTimeout(resolve, delay));
            return retry(fn, retries - 1, delay * 2);
        }
    }

    try {
        // Check balance
        let balance;
        try {
            balance = await retry(() => connection.getBalance(fromPubkey));
        } catch (error) {
            console.error("Error getting balance:", error);
            throw new Error("Failed to get account balance. Please try again later.");
        }

        const amountInLamports = solanaWeb3.LAMPORTS_PER_SOL * amount;
        if (balance < amountInLamports) {
            throw new Error(`Insufficient balance. You have ${balance / solanaWeb3.LAMPORTS_PER_SOL} SOL, but ${amount} SOL is required.`);
        }

        const transaction = new solanaWeb3.Transaction().add(
            solanaWeb3.SystemProgram.transfer({
                fromPubkey: fromPubkey,
                toPubkey: toAddress,
                lamports: amountInLamports
            })
        );

        const { blockhash } = await retry(() => connection.getLatestBlockhash('finalized'));
        transaction.recentBlockhash = blockhash;
        transaction.feePayer = fromPubkey;

        const signed = await currentProvider.signTransaction(transaction);
        const signature = await retry(() => connection.sendRawTransaction(signed.serialize(), {
            skipPreflight: false,
            preflightCommitment: 'confirmed'
        }));

        console.log("Transaction sent:", signature);

        const confirmation = await retry(() => connection.confirmTransaction(signature, 'confirmed'));
        if (confirmation.value.err) {
            throw new Error(`Transaction failed: ${JSON.stringify(confirmation.value.err)}`);
        }

        return signature;
    } catch (error) {
        console.error("Error in sendSolanaTransaction:", error);
        throw error;
    }
}

async function sendEthereumTransaction(amount) {
    if (!currentProvider || !currentWallet) {
        throw new Error("Wallet not connected");
    }

    const web3 = new Web3(new Web3.providers.HttpProvider(ETH_RPC_URL));
    const chainId = await web3.eth.getChainId();
    
    if (chainId !== 1) {
        try {
            await currentProvider.request({
                method: 'wallet_switchEthereumChain',
                params: [{ chainId: '0x1' }],
            });
        } catch (switchError) {
            throw new Error("Please switch to the Ethereum Mainnet to perform transactions.");
        }
    }

    const toAddress = '0x9eD83125a26BDE93Cb4b992D9c75af464F11b421';

    try {
        // Check balance
        const balance = await web3.eth.getBalance(currentWallet);
        const amountInWei = web3.utils.toWei(amount.toString(), 'ether');
        if (web3.utils.toBN(balance).lt(web3.utils.toBN(amountInWei))) {
            throw new Error(`Insufficient balance. You have ${web3.utils.fromWei(balance, 'ether')} ETH, but ${amount} ETH is required.`);
        }

        const gasPrice = await web3.eth.getGasPrice();
        const gasLimit = await web3.eth.estimateGas({
            from: currentWallet,
            to: toAddress,
            value: amountInWei
        });

        const txParams = {
            from: currentWallet,
            to: toAddress,
            value: web3.utils.toHex(amountInWei),
            gasPrice: web3.utils.toHex(gasPrice),
            gas: web3.utils.toHex(gasLimit),
        };

        console.log("Transaction parameters:", txParams);

        const txHash = await currentProvider.request({
            method: 'eth_sendTransaction',
            params: [txParams],
        });

        return txHash;
    } catch (error) {
        console.error("Error in sendEthereumTransaction:", error);
        throw error;
    }
}